@import "../variables.scss";
.ant-card-bordered {
  flex-direction: row;
  display: flex;
  .ant-card-cover {
    img {
      width: 200px;
    }
  }
}
#route-product-list {
  .list-header {
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
    }
    height: unset;
    line-height: unset;
    padding: 12px 60px 12px 12px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    direction: ltr;
    &.jd {
      position: fixed;
      z-index: 100;
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      padding-right: 58px;
      width: 84vw;
      html[dir="rtl"] & {
        right: 248px;
        @media screen and (max-width: 768px) {
          right: 0;
        }
      }
    }
    .header-actions {
      flex: 1;
      button {
        margin-right: 8px;
        html[dir="rtl"] & {
          margin-right: 0px;
          margin-left: 8px;
        }
      }
      a {
        color: #5f5f5f;
        .anticon-caret-down {
          margin-left: 4px;
          font-size: 10px;
          html[dir="rtl"] & {
            margin-left: 0px;
            margin-right: 4px;
          }
        }
      }
      .ant-divider {
        background: #5f5f5f;
      }
    }
    .header-search {
      width: 240px;
      &.jd {
        margin-right: 44px;
      }
      html[dir="rtl"] & {
        input {
          padding-right: 56px;
        }
      }
    }
  }
  .list-main {
    &.jd {
      margin-top: 68px;
      html[dir="rtl"] & {
        margin-right: 250px;
        @media (max-width: 768px) {
          margin-right: 0;
        }
      }
      html[dir="ltr"] & {
        margin-left: 250px;
        @media (max-width: 768px) {
          margin-left: 0;
        }
      }
    }
    margin-top: 16px;
    .list-main-sider {
      background-color: #f4f3f4;
      .cats-header {
        height: 64px;
        box-sizing: border-box;
        border-right: 1px solid #e1e1e1;
        border-bottom: 1px solid #e1e1e1;
        background-color: #fff;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        .text {
          color: #414114;
          font-weight: 600;
        }
        button {
          border: 1px solid $color-green;
          color: $color-green;
        }
        html[dir="rtl"] & {
          border-right: none;
          border-left: 1px solid #e1e1e1;
          button {
            span {
              margin-left: 0px;
              margin-right: 8px;
            }
          }
        }
      }
      .cats-list {
        display: flex;
        flex-direction: column;
        ul {
          margin: 0;
          padding: 0;
          li {
            list-style: none;
          }
        }
        .ant-menu {
          .ant-menu-item {
            html[dir="rtl"] & {
              padding-left: 16px !important;
              padding-right: 24px !important;
              text-align: right;
              span {
                margin-left: 10px;
                font-size: 15px;
                i {
                  margin-right: 0px;
                  margin-left: 14px;
                }
              }
            }
          }
        }

        .category {
          position: relative;
          height: 64px;
          box-sizing: border-box;
          padding: 0 32px;
          border-right: 1px solid #e1e1e1;
          border-bottom: 1px dashed #e1e1e1;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          .name {
            color: #414114;
          }
          .count {
            color: #969596;
          }
          .children-menu {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
          }
          &.current {
            background-color: #eefbf988;
            .name {
              color: $color-green;
              font-weight: 600;
            }
            &:before {
              content: "";
              position: absolute;
              left: 0;
              width: 4px;
              height: 100%;
              background-color: $color-green;
            }
            &:after {
              content: "";
              position: absolute;
              right: -7px;
              width: 12px;
              height: 12px;
              border: 1px solid #e1e1e1;
              background-color: #fff;
              transform: rotate(45deg);
            }
          }
          html[dir="rtl"] & {
            border-right: none;
            border-left: 1px solid #e1e1e1;
            &.current {
              &:before {
                left: unset;
                right: 0;
              }
              &:after {
                right: unset;
                left: -7px;
              }
            }
          }
        }
      }
      .cats-message {
        padding: 16px;
        text-align: center;
        color: #999999;
      }
    }
    .list-main-content {
      background-color: #fff;
      position: relative;
      z-index: 9;
    }
    .list-main-content-filter {
      @media screen and (max-width: 768px) {
        display: flex;
        flex-wrap: wrap;
      }
      background-color: transparent;
      padding: 0 16px;
      border-bottom: 1px solid #e1e1e1;
      a {
        color: #414114;
        &.current {
          color: $color-green;
        }
      }
      .ant-divider {
        margin: 0 10px;
        background: #ccc;
      }
    }
    .list-main-content-actions {
      background-color: transparent;
      padding: 0 16px;
      border-bottom: 1px solid #e1e1e1;
      .ant-checkbox-wrapper {
        margin-right: 12px;
        color: #414114;
      }
      button {
        height: 28px;
        padding: 0px 12px;
        margin-right: 8px;
        color: #414114;
      }
      a {
        margin-left: 8px;
        color: #414114;
        i {
          margin-left: 4px;
          font-size: 10px;
        }
      }
      html[dir="rtl"] & {
        .ant-checkbox-wrapper {
          margin-right: 0px;
          margin-left: 12px;
        }
        button {
          margin-right: 0px;
          margin-left: 8px;
        }
        a {
          margin-left: 0px;
          margin-right: 8px;
          i {
            margin-left: 0px;
            margin-right: 4px;
          }
        }
      }
    }
    .list-main-content-data {
      .content-data-wrap {
        display: flex;
        flex-wrap: wrap;
        .ant-row {
          width: 100%;
        }
        .product-list-item {
          position: relative;
          box-sizing: border-box;
          padding: 22px 12px;
          border-right: 1px dashed #e1e1e1;
          border-bottom: 1px dashed #e1e1e1;
          display: flex;
          flex-direction: column;
          $image-size: 120px;
          .item-row {
            position: relative;
            display: flex;
            flex-direction: row;
            .item-image {
              position: relative;
              width: $image-size;
              background: #eee;
              display: flex;
              flex-direction: column;
              align-items: center;
              > div {
                border: 1px solid #eee;
              }
              .item-price {
                display: flex;
                flex-direction: column;
                width: 100%;
                background: #eee;
                padding: 10px;

                .input-block {
                  border-bottom: 1px dashed #ddd;
                  padding: 3px 0;
                  flex: 1;
                  .stop-discount {
                    background: $color-green;
                    color: #fff;
                    border-radius: 2px;
                    font-size: 9px;
                    padding: 2px;
                  }
                  .item-original-price-input {
                    text-decoration: line-through;
                    display: inline-block;
                    padding: 0;
                  }
                  &.price {
                    direction: ltr;
                    .real-price {
                      color: #ff5757;
                      text-align: left;
                      padding: 0;
                      margin: 0;
                    }
                    .item-price-input {
                      font-size: 18px;
                    }
                  }
                }
              }
              img {
                width: 100%;
                height: auto;
              }
              .offShelf-mask {
                position: absolute;
                left: 0;
                top: $image-size - 24px;
                width: $image-size;
                height: 24px;
                background-color: #00000099;
                line-height: 24px;
                text-align: center;
                color: #fff;
              }
              .ant-checkbox-wrapper {
                position: absolute;
                left: -4px;
                top: -8px;
              }
            }
            .item-main {
              min-width: 156px;
              flex: 1;
              margin-left: 8px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .item-name {
                padding: 0 6px;
                border: 1px solid #e1e1e1;
                cursor: text;
                overflow: hidden;
                white-space: nowrap;
              }
              .ant-input {
                border-radius: 0;
              }
              .item-sales {
                color: #999;
                span {
                  margin-right: 12px;
                }
              }
              .price-stats {
                margin-top: 10px;
                font-size: 12px;
                text-align: center;
              }
              .percents {
                .ant-statistic-title {
                  font-size: 12px;
                }
                .ant-statistic-content {
                  font-size: 16px;
                }
                &.net {
                  text-align: center;
                  border-top: 1px solid #ccc;
                  margin: 5px 0;
                  padding: 5px 0;
                  .ant-statistic-title {
                    font-size: 16px;
                  }
                  .ant-statistic-content {
                    font-size: 24px;
                  }
                }
              }
              .item-price {
                display: flex;
                flex-direction: row;
                margin-top: 10px;
                .input-block {
                  margin-right: 10px;
                  font-size: 12px;

                  .item-price-input,
                  .item-quantity-input {
                    display: inline-block;
                    width: 68px;
                    margin-left: 2px;
                    padding: 0 6px;
                    border: 1px solid #e1e1e1;
                    cursor: text;
                    text-align: center;
                    &.red {
                      color: #ff5757;
                    }
                    &.borderless {
                      border-width: 0;
                    }
                  }
                  .item-price-input {
                    border: none;
                  }
                  .item-product-number-input {
                    display: inline-block;
                    margin-left: 2px;
                    padding: 0 6px;
                    border: 1px solid #e1e1e1;
                    cursor: text;
                    text-align: center;
                  }
                  .ant-input-number {
                    width: 82px;
                    margin-left: 2px;
                    text-align: center;
                    .ant-input-number-input {
                      direction: ltr;
                    }
                  }

                  .item-original-price-input {
                    text-decoration: line-through;
                    display: inline-block;
                    padding: 0;
                  }
                  &.price {
                    direction: ltr;
                    .real-price {
                      color: #ff5757;
                      text-align: left;
                      padding: 0;
                      margin: 0;
                    }
                  }
                }
              }
            }
            &.tags {
              margin-top: 8px;
              flex-wrap: wrap;
            }
            &.actions {
              margin-top: 16px;
              justify-content: flex-end;
              align-items: center;
              button {
                border-color: $color-green;
                color: $color-green;
                &.ant-btn-primary {
                  color: #fff;
                }
              }
              a {
                margin-right: 12px;
                i {
                  margin-right: 8px;
                }
              }
            }
          }
          &:nth-child(even) {
            border-right: unset;
          }
          &.offShelf {
            background-color: #e8ebf0;
          }
          &.editmode {
            .ant-input {
              border: 1px solid $color-green;
              box-shadow: 0px 0px 2px 0px $color-green;
            }
          }
          .sku-img {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 64px;
            height: auto;
          }
          html[dir="rtl"] & {
            border-right: none;
            border-left: 1px dashed #e1e1e1;
            .item-row {
              .item-image {
                .ant-checkbox-wrapper {
                  left: unset;
                  right: -4px;
                }
              }
              .item-main {
                margin-left: 0px;
                margin-right: 8px;
                .item-sales {
                  span {
                    margin-right: 0px;
                    margin-left: 12px;
                  }
                }
                .item-price {
                  .input-block {
                    margin-right: 0px;
                    margin-left: 10px;
                    font-size: 12px;
                    .item-price-input,
                    .item-quantity-input {
                      margin-left: 0px;
                      margin-right: 2px;
                    }
                    .ant-input-number {
                      margin-left: 0px;
                      margin-right: 2px;
                    }
                  }
                }
              }
              &.actions {
                direction: ltr;
                a {
                  direction: rtl;
                  i {
                    margin-left: 0px;
                    margin-left: 8px;
                  }
                }
              }
            }
            &:nth-child(even) {
              border-left: unset;
            }
          }
        }
      }
      .content-data-pagination {
        padding: 16px;
        text-align: center;
      }
      .content-data-wrap-message {
        width: 100%;
        padding: 16px;
        text-align: center;
        color: #999999;
      }
    }
  }
}

#route-product-list-actions-more-popover {
  .ant-btn {
    width: 100%;
    margin-top: 8px;
    &:first-child {
      margin-top: 0;
    }
  }
}

.route-product-list-modal-category {
  min-width: 700px;
  .ant-transfer {
    height: 300px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .ant-transfer-list {
      flex: 1;
      height: 100%;
    }
    .ant-transfer-operation {
      margin: 0 32px;
      .ant-btn {
        margin-bottom: 12px;
        &:nth-child(2) {
          direction: rtl;
          span {
            margin-left: 0;
            margin-right: 8px;
          }
        }
      }
    }
    html[dir="rtl"] & {
      .ant-transfer-list-header {
        direction: rtl;
        .ant-transfer-list-header-title {
          right: unset;
          left: 12px;
        }
      }
      .ant-transfer-list-content-item {
        direction: rtl;
        text-align: start;
      }
      .ant-transfer-operation {
        .ant-btn {
          width: 100px;
          i {
            transform: rotate(180deg);
          }
          &:nth-child(1) {
            direction: rtl;
            span {
              margin-left: 0px;
              margin-right: 8px;
            }
          }
          &:nth-child(2) {
            direction: ltr;
            span {
              margin-left: 8px;
              margin-right: 0px;
            }
          }
        }
      }
    }
  }
}
html[dir="rtl"] {
  .ant-form-item{
    width: 100%;
    .ant-row{
      width: 100%;

      .ant-col{
        min-width: 200px;
      }
    }
  }
}
#route-product-edit {
  background-color: #fff;
  .edit-header {
    height: 48px;
    line-height: 48px;
    padding: 0 8px;
    background-color: #f9f9f9;
    .back {
      display: inline-block;
      font-weight: 600;
      i {
        margin-right: 4px;
        font-weight: 600;
      }
    }
    .ant-divider {
      top: 2px;
      width: 2px;
      height: 1em;
      vertical-align: unset;
      background: $color-green;
    }
    .title {
      color: #414114;
      font-weight: 600;
    }
    html[dir="rtl"] & {
      .back {
        i {
          margin-right: 0px;
          margin-left: 4px;
          transform: rotate(180deg);
        }
      }
    }
  }
  .edit-content {
    position: relative;
    padding: 16px 0;
    background-color: #fff;
    form {
      .product-tabs {
        .product-tab {
          padding: 0px 10px;
          &.required {
            &::before {
              display: inline-block;
              margin-right: 4px;
              color: #ff4d4f;
              font-size: 14px;
              font-family: SimSun, sans-serif;
              line-height: 1;
              content: "*";
            }
          }
        }
      }
      .ant-form-item {
        &.standard {
          .ant-form-item-control-wrapper {
            &.standard {
              padding: 6px 0;
              border: 1px solid #eee;
              display: flex;
              flex-wrap: wrap;
              .item-input {
                margin-bottom: 0;
                box-sizing: border-box;
                padding: 8px;
                .ant-form-item-label {
                  line-height: unset;
                }
                .ant-input-number {
                  width: 100%;
                }
              }
            }
          }
        }
      }
      html[dir="rtl"] & {
        .ant-form-item {
          display: flex;
          .ant-form-item-label {
            width: 20%;
            padding: 0 8px;
            text-align: left;
          }
          .ant-upload-picture-card-wrapper {
            display: flex;
          }
          .ant-select {
            .ant-select-selection-selected-value {
              float: right;
            }
          }
          .ant-upload-list-item {
            float: right;
            margin-right: 0px;
            margin-left: 8px;
          }
          .ant-upload-select-picture-card {
            margin-right: 0px;
            margin-left: 8px;
          }
          &.standard {
            .ant-form-item-control-wrapper {
              &.standard {
                .item-input {
                  display: flex;
                  flex-direction: column;
                  .ant-form-item-label {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
    .sku-img {
      position: absolute;
      right: 0;
      top: 0;
      width: 169px;
      height: auto;
    }
    html[dir="rtl"] & {
      .sku-img {
        right: unset;
        left: 0;
      }
    }
  }
  .edit-footer {
    height: 48px;
    line-height: 48px;
    padding: 0 8px;
    text-align: center;
    background-color: #f9f9f9;
    .ant-btn {
      margin: 0 12px;
      border: 1px solid $color-green;
      color: $color-green;
      &.ant-btn-primary {
        border: unset;
        color: #fff;
      }
    }
  }
}
.ant-card-meta-title {
  white-space: normal;
}
