@import "../../styles/variables.scss";

.weapp-input{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .ant-input{
        font-size: 12px;
    }
}