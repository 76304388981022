#route-home {
	.statistic-numbers {
		display: flex;
		flex-direction: row;
		.numbers-col {
			flex: 1;
			margin-right: 8px;
			html[dir="rtl"] & {
				margin-right: 0px;
				margin-left: 8px;
				&:last-child {
					margin-left: unset;
				}
			}
			&:last-child {
				margin-right: unset;
			}
			.col-box {
				height: 130px;
				background: #fff;
				display: flex;
				flex-direction: row;
				align-items: center;
				.box-block {
					flex: 1;
					height: 90%;
					display: flex;
					flex-direction: column;
					align-items: center;
					h5 {
						margin: 0;
						color: #9a9a9a;
					}
					div {
						margin-top: 12px;
						.num {
							color: #2d2d2d;
							font-size: 30px;
						}
						.unit {
							margin: 0 4px;
							color: #2d2d2d;
						}
					}
				}
				.ant-divider {
					height: 40%;
				}
			}
			&.three {
				flex: 1.5;
			}
		}
	}
	.block-container {
		margin-top: 16px;
		.block-header {
			padding: 8px 24px;
			background-color: #f9f9f9;
			.title {
				font-size: 12px;
				color: #262626;
				font-weight: bold;
			}
			.ant-radio-group {
				margin: 0 16px;
				font-size: 10px;
				.ant-radio-button-wrapper-checked {
					background-color: $color-green;
					color: #fff;
				}
			}
		}
		.block-content {
			background-color: #fff;
			canvas {
				width: 100% !important;
				direction: ltr !important;
			}
			.g2-tooltip {
				html[dir="rtl"] & {
					.g2-tooltip-list {
						li {
							.g2-tooltip-marker {
								margin-right: 0px !important;
								margin-left: 8px !important;
							}
						}
					}
				}
			}
		}
		.qrcode{
			background-color: #fff;
			padding: 10px;
			text-align: center;
		}
	}
}