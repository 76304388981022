@import "../../styles/variables.scss";

.links {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  .item {
    width: 200px;
    font-size: 14px;
    margin: 6px 0;
  }
}
