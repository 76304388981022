@import "../../../styles/variables.scss";
.course {
  background-color: #fff;
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #e1e1e1;
  min-width: 1250px;
  .header {
    margin: 10px 0;
    display: flex;
    .actions {
      flex: 1;
    }
    .search {
      width: 300px;
      display: flex;
      input {
        margin: 0 10px;
        border-radius: 3px;
      }
    }
  }
  .info {
    display: flex;
    flex-direction: row;
    .cover {
      img {
        max-width: 100px;
        height: auto;
      }
    }
    .name {
      margin: 0 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;
      max-height: 100px;
    }
  }
}
