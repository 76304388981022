@import "../../../styles/variables.scss";

.category-form {
  background-color: #fff;
  border-radius: 5px;
  padding: 30px;
  margin: 10px 0;
  border: 1px solid #e1e1e1;
  .ant-form-item {
    max-width: 800px;
    min-width: 700px;
  }
  .submit {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    background: #fff;
    width: 100%;
  }
}
