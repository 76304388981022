#route-setting-shop {
  .header-tabs {
    background-color: #fff;
    display: flex;
    flex-direction: row;
    a {
      height: 32px;
      box-sizing: border-box;
      padding: 0 16px;
      line-height: 32px;
      color: #414141;
      text-align: center;
      &.current {
        color: $color-green;
        border-bottom: 3px solid $color-green;
      }
    }
  }
  .content-header {
    margin-top: 16px;
    padding: 8px 16px;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: row;
    align-items: center;
    .title {
      color: #414141;
    }
    .actions {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      .ant-btn {
        height: 20px;
        padding: 0 8px;
        font-size: 12px;
        color: $color-green;
        border-color: $color-green;
      }
    }
  }
}

#route-setting-lang {
  .lang-header {
    height: 48px;
    line-height: 48px;
    padding: 0 8px;
    background-color: #f9f9f9;
    .back {
      display: inline-block;
      font-weight: 600;
      i {
        margin-right: 4px;
        font-weight: 600;
      }
    }
    .ant-divider {
      top: 2px;
      width: 2px;
      height: 1em;
      vertical-align: unset;
      background: $color-green;
    }
    .title {
      color: #414114;
      font-weight: 600;
    }
    html[dir="rtl"] & {
      .back {
        i {
          margin-right: 0px;
          margin-left: 4px;
          transform: rotate(180deg);
        }
      }
    }
  }
  .list-wrap {
    margin-bottom: 16px;
    padding: 16px;
    background-color: #ffffff;
    .list-label {
      color: #999999;
    }
    .lang-list {
      margin-top: 12px;
      display: flex;
      flex-wrap: wrap;
      button {
        font-family: $ug-font !important;
        html[dir="rtl"] & {
          margin-right: 0px;
          margin-left: 24px;
        }
      }
    }
  }
}

.steps {
}

.steps-content {
  padding: 10px 0;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &.error {
    color: #ff4d4f;
  }
}
.steps-action {
  display: flex;
  align-items: center;
  flex-direction: column;
  button {
    margin: 10px;
  }
}
