@import "../../styles/variables.scss";
.media {
  display: flex;
  min-width: 800px;
  background: #fff;
  .folder {
    width: 200px;
    min-width: 200px;
    border-right: 1px solid #e1e1e1;
    height: 500px;
    padding: 10px 20px 10px 0;
    display: flex;
    flex-direction: column;
    position: relative;

    .list {
      .ant-tree-treenode {
        height: 40px;
        line-height: 40px;
        justify-content: center;
        align-items: center;
        &:before {
          border-radius: 5px;
          &:hover {
            background-color: #ddf8ff;
          }
        }
        .ant-tree-iconEle {
          .anticon {
            .svg {
              width: 32px;
              height: 32px;
            }
          }
        }
      }
      .ant-tree-node-content-wrapper {
        display: flex;
        .ant-tree-title {
          flex: 1;
        }
      }
      .ant-tree-switcher {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .item {
        display: flex;
        flex: 1;
        .title {
          flex: 1;
        }
        .actions {
          width: 80px;
          span {
            padding: 0 5px;
          }
        }
      }
    }
    .action-bar {
      position: absolute;
      bottom: 0;
      height: 50px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .files {
    height: 500px;
    min-width: 600px;
    flex: 1;
    padding: 10px;
    .header {
      display: flex;
      padding: 0 0 10px 0;
      .search {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        input {
          width: 200px;
        }
        span {
          position: absolute;
          right: 20px;
          bottom: 8px;
        }
      }
      .actions {
        width: 100px;
      }
    }
    .list {
      .ant-table {
        min-height: 400px;
      }

      .ant-table-cell {
        .info {
          display: flex;
          justify-content: center;
          align-items: center;
          .file {
            justify-content: center;
            align-items: center;
            flex: 1;
            padding: 10px;
            min-width: 100px;
            max-width: 100px;
            img {
              min-width: 80px;
              width: 80px;
              height: 80px;
            }
            video {
              min-width: 80px;
              width: 80px;
              height: 80px;
            }
            audio{
              min-width: 100px;
              width: 100px;
              
            }
          }
          .name {
            justify-content: center;
            align-items: center;
            flex: 1;
            overflow: hidden;
            padding: 0 10px;
          }
        }
        td {
          padding: 5px;
        }
      }
    }
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 3px;
  }
}
