@import "../../../styles/variables.scss";
.course {
  .ant-card-body {
    display: flex;
    flex-direction: row;
    .cover {
      max-width: 300px;
    }
    .name-price {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-left: 20px;
      .label {
        margin: 0 10px;
      }
      .price {
        color: $color-red;
        &::before {
          content: "￥";
        }
      }
    }
  }
}
