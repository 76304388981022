@import "../../styles/variables.scss";

.courses {
  .list {
    .article-name {
      width: 100%;
      align-items: center;
      justify-content: flex-start;
      display: flex;
    }
  }
}
