#route-login {
  width: 100%;
  height: 100%;
  background: url("../images/login-background.jpg") 100% no-repeat;
  background-size: cover;
  direction: ltr;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .login-box-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    img {
      width: 200px;
      height: auto;
    }
    .login-box {
      background: rgba(255, 255, 255, 0.5);
      border-radius: 10px;
      padding: 20px;
      .login-title {
        text-align: center;
        font-size: 24px;
        font-family: PingFang SC;
        line-height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .login-form {
      .ant-form-item {
        margin-bottom: 32px;
      }
      .ant-input-affix-wrapper {
        padding: 0 11px;
        border-radius: 10px;
      }
      .ant-input-prefix {
        top: 52%;
        left: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        html[dir="rtl"] & {
          top: 52%;
          left: unset;
          right: 24px;
        }
        .anticon {
          font-size: 18px;
          color: rgba($color: #000, $alpha: 0.5);
        }
      }
      .ant-form-explain {
        padding-left: 24px;
        padding-top: 8px;
        font-size: 15px;
        font-weight: bold;
        color: $color-yellow;
        html[dir="rtl"] & {
          direction: rtl;
          padding-left: unset;
          padding-right: 24px;
        }
      }
      .ant-input {
        height: 38px;
        padding: 0 5px;
        border-color: transparent;
        font-size: 16px;
        font-weight: bold;
        color: rgba($color: #000, $alpha: 0.5);
        letter-spacing: 4px;
        &::-webkit-input-placeholder {
          font-size: 14px;
          font-weight: unset;
          color: rgba($color: #000, $alpha: 0.5);
          letter-spacing: 2px;
          html[dir="rtl"] & {
            direction: rtl;
          }
        }
        html[dir="rtl"] & {
          padding: 0 24px;
          padding-right: 48px;
        }
      }
      .login-button {
        justify-content: center;
        .ant-btn {
          width: 100%;
          height: 38px;
          border-radius: 10px;
          border-color: transparent;
          background-color: #36b5e6;
          font-size: 16px;
          font-weight: bold;
          color: #fff;
          letter-spacing: 8px;
          html[dir="rtl"] & {
            direction: rtl;
            letter-spacing: 2px;
          }
        }
      }
    }
  }
}
