/* page related */
body {
  margin: 0;
  padding: 0;
  html[dir="rtl"] & {
    * {
      font-family: $ug-font !important;
    }
  }
}

/* react related */
#root {
  position: fixed;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

/* ant global related */
.ant-message {
  html[dir="rtl"] & {
    i {
      margin-right: 0px !important;
      margin-left: 8px;
    }
  }
}

.ant-modal {
  html[dir="rtl"] & {
    .ant-modal-close {
      right: unset;
      left: 0;
    }
    .ant-confirm-body {
      .anticon {
        float: right;
        margin-right: 0px;
        margin-left: 16px;
      }
      .ant-confirm-title {
        text-align: justify;
      }
      .ant-confirm-content {
        margin-left: 0px;
        margin-right: 38px;
        margin-top: 16px;
      }
    }
    .ant-confirm-btns {
      float: left;
      .ant-btn {
        margin-left: 0px;
        margin-right: 8px;
      }
    }
    .ant-modal-footer {
      text-align: left;
      .ant-btn {
        margin-left: 8px;
        &.ant-btn-loading {
          direction: ltr;
        }
      }
    }
  }
}

.ant-calendar-picker {
  direction: ltr !important;
}
.ant-calendar-picker-container {
  html[dir="rtl"] & {
    direction: ltr !important;
  }
}

.ant-pagination {
  direction: ltr !important;
}

.ant-select-dropdown {
  html[dir="rtl"] & {
    direction: ltr !important;
    .ant-select-dropdown-menu-item {
      text-align: end;
    }
  }
}

.ant-radio-group {
  html[dir="rtl"] & {
    .ant-radio-button-wrapper {
      &:before {
        display: none;
      }
      &:first-child {
        border-radius: 0 4px 4px 0;
      }
      &:last-child {
        border-radius: 4px 0 0 4px;
        border-left: 1px solid #d9d9d9;
        &.ant-radio-button-wrapper-checked {
          border-left: 0;
        }
      }
    }
    .ant-radio-wrapper {
      margin-right: 0px;
      margin-left: 8px;
    }
  }
}

.ant-popover {
  html[dir="rtl"] & {
    .ant-popover-message {
      position: relative;
      i {
        right: 0;
      }
      .ant-popover-message-title {
        padding-left: 0px;
        padding-right: 22px;
      }
    }
    .ant-popover-buttons {
      text-align: left;
      .ant-btn {
        margin-left: 0px;
        margin-right: 8px;
      }
    }
  }
}

.ant-pagination {
  html[dir="rtl"] & {
    direction: rtl !important;
    li {
      margin-right: unset;
      margin-left: 8px;
      &.ant-pagination-prev,
      &.ant-pagination-jump-prev,
      &.ant-pagination-next,
      &.ant-pagination-jump-next {
        &::after,
        a {
          transform: rotate(180deg);
        }
      }
      &.ant-select-dropdown-menu-item {
        margin: unset;
        direction: rtl;
        text-align: start;
      }
    }
  }
}

.ant-select {
  html[dir="rtl"] & {
    .ant-select-selection__placeholder {
      left: unset;
      right: 0;
      text-align: right;
    }
    .ant-select-selection-selected-value {
      float: right;
      padding-right: unset;
      padding-left: 20px;
    }
    .ant-select-arrow {
      right: unset;
      left: 11px;
    }
  }
}

// custom global
.route-base-image-preview-modal {
  .ant-modal-body {
    padding: 0;
  }
  img {
    width: 100%;
    height: auto;
    max-height: 700px;
  }
  video {
    width: 100%;
    height: auto;
    max-height: 700px;
  }
}

.bf-container {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
