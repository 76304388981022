@import "../../../styles/variables.scss";
.study {
  background-color: #fff;
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #e1e1e1;
  position: relative;

  .header {
    margin: 10px 0;
    display: flex;
    .actions {
      flex: 1;
    }
    .search {
      width: 300px;
      display: flex;
      input {
        margin: 0 10px;
        border-radius: 3px;
      }
    }
  }

  .ant-table-cell {
    .info {
      display: flex;
      justify-content: center;
      align-items: center;
      .avatar {
        justify-content: center;
        align-items: center;
        flex: 1;
        padding: 10px;
        min-width: 70px;
        max-width: 70px;
      }
      .name {
        justify-content: center;
        align-items: center;
        flex: 1;
        overflow: hidden;
        padding: 0 10px;
      }
    }
    .study-time{
      
    }
    td {
      padding: 5px;
    }
  }
}
