.distribute-info {
  display: flex;
  .item {
    flex: 1;
    flex-direction: column;
    display: flex;
    .title {
      font-size: 12px;
      color: rgba($color: #000000, $alpha: 0.65);
    }
    .desc {
      font-size: 10px;
    }
  }
}
.line {
  border-bottom: 1px solid #f5f5f5;
  padding: 0 0 5px;
  margin: 0 0 5px;
}
