.redeem-order-list {
  .details {
    display: flex;
    flex-direction: column;
    .detail {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      margin: 10px 0;
    }
  }
}
