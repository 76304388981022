@media print {
    .deliver {
        display: none !important;
    }
    .details .item{
        border-bottom: 10px solid #ccc;
    }
    .details{
        display: none;
    }
    .ant-avatar{
        display: none !important;
    }
    .info-row products{
        .header{
            display: none !important;
        }
    }
}

