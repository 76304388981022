@import "../../styles/variables.scss";
.property-area {
  padding: 20px;
  box-sizing: border-box;
  background-color: #f5f5f5;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  position: relative;
  width: 100%;
  &.ant-form-item {
    width: 96% !important;
  }
  .item {
    min-width: 100px;
  }
  .delete-group {
    position: absolute;
    top: 0;
    left: 0;
  }
  .properties {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    .property-item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .name-wrapper {
        display: flex;
        flex-direction: row;
        .name {
          width: 200px;
          padding: 5px;
          border: 1px solid #ddd;
          border-radius: 5px;
        }
      }
    }
  }
}
