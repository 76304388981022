#route-review-list {
	.header-statistics {
		padding: 16px;
		background-color: #fff;
		font-size: 12px;
		.ant-col-20 {
			.title {
				margin-right: 8px;
			}
			.ant-divider {
				margin: 0 12px;
			}
			strong {
				margin-left: 8px;
				font-size: 14px;
			}
		}
		.ant-col-4 {
			text-align: end;
		}
		html[dir="rtl"] & {
			display: flex;
			.ant-col-20 {
				.title {
					margin-right: 0px;
					margin-left: 8px;
				}
				strong {
					margin-left: 0px;
					margin-right: 8px;
					font-size: 14px;
					display: inline-block;
					direction: ltr;
				}
			}
		}
	}
	.filter-container {
		margin-top: 16px;
		padding: 16px 32px;
		padding-top: 0;
		background-color: #fff;
		.filter-row {
			margin-top: 16px;
			.label {
				margin: 0 32px;
			}
		}
		html[dir="rtl"] & {
			.filter-row {
				.label {
					display: inline-block;
					width: 72px;
					white-space: pre;
				}
			}
		}
	}
	.list-title {
		margin-top: 16px;
		padding: 8px 16px;
		background-color: #f9f9f9;
		color: #414141;
	}
	.list-message {
		padding: 16px;
		background-color: #fff;
	}
	.review-item {
		padding: 16px;
		border-bottom: 1px solid #e1e1e1;
		background-color: #fff;
		.item-scores {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-end;
			.user-name {
				color: #414141;
			}
			.merchant-score {
				margin: 4px 0;
				color: #414141;
				font-size: 12px;
			}
			.merchant-rate {
				width: 114px;
				color: #5f5f5f;
				font-size: 12px;
				display: flex;
				.label {
					margin-top: 2px;
				}
				.ant-rate {
					font-size: 14px;
					.ant-rate-star {
						margin-right: unset;
						margin-left: 4px;
					}
				}
				&.delivery {
					margin-top: 4px;
					i {
						margin: 0 4px;
						margin-top: 4px;
						font-size: 15px;
						&.anticon-frown-o {
							color: red;
						}
						&.anticon-smile-o {
							color: $color-green;
						}
					}
					.value {
						margin-top: 2px;
						margin-left: 4px;
					}
				}
			}
		}
		.item-brief {
			box-sizing: border-box;
			padding: 0 16px;
			.time {
				font-size: 13px;
				color: #5f5f5f;
			}
			.content {
				margin-top: 4px;
				color: #414141;
				.no-content {
					font-style: italic;
					font-size: 13px;
					color: #cccccc;
				}
			}
			.images {
				margin-top: 8px;
				display: flex;
				flex-wrap: wrap;
				img {
					width: 75px;
					height: 75px;
					margin-right: 8px;
					margin-bottom: 8px;
					border-radius: 5px;
					cursor: pointer;
				}
				.no-image {
					width: 75px;
					height: 75px;
					margin-bottom: 8px;
					border: 1px solid #cccccc;
					border-radius: 5px;
					line-height: 75px;
					text-align: center;
					font-style: italic;
					font-size: 13px;
					color: #cccccc;
				}
			}
			.thumbs {
				margin-bottom: 4px;
				i {
					margin-right: 4px;
					font-size: 10px;
				}
				span {
					font-size: 10px;
					color: #999;
				}
			}
			.replies {
				font-style: italic;
				color: #999999;
			}
			.actions {
				margin-top: 8px;
				a {
					margin-right: 16px;
					i {
						margin-right: 4px;
					}
					&.secondary {
						margin-left: 8px;
						color: #5f5f5f;
					}
				}
			}
			.reply-wrap {
				position: relative;
				margin-top: 12px;
				padding: 8px;
				border: 1px solid #e1e1e1;
				border-radius: 4px;
				background-color: #f9f9f9;
				&:before{
					content: '';
					position: absolute;
					left: 20px;
					top: -5px;
					width: 10px;
					height: 10px;
					background-color: #f9f9f9;
					border-left: 1px solid #e1e1e1;
					border-top: 1px solid #e1e1e1;
					transform: rotate(45deg);
				}
				.ant-input {
					padding: 4px 8px;
					resize: none;
				}
				.reply-actions {
					margin-top: 8px;
					.ant-btn {
						margin-right: 8px;
						span {
							font-size: 12px;
						}
					}
				}
			}
		}
		.item-extra {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-end;
			.action-detail {
				font-size: 12px;
				i {
					font-size: 10px;
					margin-left: 4px;
				}
			}
		}
		html[dir="rtl"] & {
			display: flex;
			.item-scores {
				.merchant-rate {
					width: unset;
					.label {
						margin-top: unset;
					}
					.ant-rate {
						font-size: 14px;
						.ant-rate-star {
							margin-right: 4px;
							margin-left: 0px;
						}
					}
					&.delivery {
						i {
							margin: 0 4px;
							margin-top: 2px;
						}
						.value {
							width: 63px;
							margin-top: unset;
							margin-left: 0px;
							margin-right: 4px;
						}
					}
				}
			}
			.item-brief {
				.images {
					img {
						margin-right: 0px;
						margin-left: 8px;
					}
				}
				.thumbs {
					i {
						margin-right: 0px;
						margin-left: 4px;
					}
				}
				.actions {
					a {
						margin-right: 0px;
						margin-left: 16px;
						i {
							margin-right: 0px;
							margin-left: 4px;
						}
						&.secondary {
							margin-left: 0px;
							margin-right: 8px;
						}
					}
				}
				.reply-wrap {
					&:before{
						left: unset;
						right: 20px;
					}
					.reply-actions {
						.ant-btn {
							margin-right: 0px;
							margin-left: 8px;
						}
					}
				}
			}
			.item-extra {
				.action-detail {
					i {
						margin-left: 0px;
						margin-right: 4px;
					}
				}
			}
		}
	}
	.list-pagination {
		padding: 16px;
		text-align: center;
	}
}

#review-list-item-detail-popover {
	width: 200px;
	.detail-row {
		margin-top: 4px;
		direction: ltr;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		&:first-child {
			margin-top: unset;
		}
	}
}