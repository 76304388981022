#route-proxy-list {
  .filter-container {
    padding: 16px 32px;
    padding-top: 0;
    background-color: #fff;
    .ant-col-16 {
      .filter-row {
        margin-top: 16px;
        .label {
          margin: 0 28px;
        }
        .ant-input-affix-wrapper {
          width: 200px;
        }
        html[dir="rtl"] & {
          .label {
            display: inline-block;
            width: 96px;
            white-space: pre;
          }
        }
      }
    }
    .ant-col-8 {
      text-align: end;
      .ant-btn {
        margin-top: 16px;
      }
    }
    html[dir="rtl"] & {
      .ant-col-16 {
        float: right;
        .ant-input-affix-wrapper {
          width: 240px;
          input {
            direction: ltr;
            &::-webkit-input-placeholder {
              text-align: right;
            }
          }
        }
      }
      .ant-col-8 {
        float: right;
      }
    }
  }
  .list-title {
    margin: 16px 0;
    padding: 8px 16px;
    background-color: #f9f9f9;
    color: #414141;
  }
  .list-message {
    padding: 16px;
    background-color: #fff;
  }
  .list-wrap {
    display: flex;
    flex-wrap: wrap;
    .ant-card {
      width: 253px;
      margin-top: 8px;
      margin-right: 8px;
      border-radius: 4px;
      .ant-card-body {
        padding: 16px 24px;
        padding-bottom: 0px;
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        img {
          width: 80px;
          height: 80px;
          border-radius: 80px;
        }
        h3 {
          margin: 12px 0;
          color: #333;
          font-weight: bold;
        }
        .card-item-row {
          width: 100%;
          margin: 0px;
          margin-top: 4px;
          span {
            font-size: 14px;
            &:first-child {
              color: #666;
              white-space: pre;
            }
            &:last-child {
              color: #333;
              font-weight: bold;
              &.ltr {
                display: inline-block;
                direction: ltr;
              }
            }
          }
        }
        .card-item-actions {
          width: 100%;
          margin-top: 8px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          a {
            flex: 1;
            padding: 8px 0;
            text-align: center;
            i {
              margin: 0 8px;
              font-size: 16px;
            }
          }
        }
      }
      html[dir="rtl"] & {
        margin-right: unset;
        margin-left: 8px;
      }
    }
  }
  .list-pagination {
    padding: 16px;
    text-align: center;
  }
}

.route-proxy-list-edit-modal {
  width: 550px !important;
  .ant-modal-body {
    padding: 0;
  }
  .proxy-edit-wrap {
    max-height: 550px;
    padding: 16px;
    background-color: transparent;
    overflow-y: auto;
  }
  html[dir="rtl"] & {
    .proxy-edit-wrap {
      .ant-form {
        .ant-row {
          .ant-col-4,
          .ant-col-8,
          .ant-col-20 {
            float: right;
          }
          .ant-col-4 {
            width: 32.666667%;
            label {
              white-space: pre;
              &::before {
                margin-right: unset;
                margin-left: 4px;
              }
              &::after {
                margin: 0 2px 0 8px;
              }
            }
          }
          .ant-col-8 {
            width: 52.333333%;
            .ant-input {
              direction: ltr;
              &::-webkit-input-placeholder {
                text-align: right;
              }
            }
          }
          .ant-col-20 {
            width: 67.333333%;
          }
        }
      }
    }
  }
}
