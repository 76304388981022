@import "../../../styles/variables.scss";
.lesson {
  background-color: #fff;
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #e1e1e1;
  position: relative;

  .header {
    margin: 10px 0;
    display: flex;
    .actions {
      flex: 1;
    }
    .search {
      width: 300px;
      display: flex;
      input {
        margin: 0 10px;
        border-radius: 3px;
      }
    }
  }
}
