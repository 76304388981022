.video-upload {
  .upload-button {
    position: relative;
    height: 80px;
    width: 80px;
    padding: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    :hover {
      background-color: #d9d9d9;
    }
  }
  .preview {
    video {
      max-width: 200px;
      max-height: 200px;
    }
  }
}
