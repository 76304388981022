@import "../../styles/variables.scss";
.image-upload {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .preview {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .preview-item {
      position: relative;
      .image {
        width: 100px;
        height: 100px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 10px;
        border: 1px dashed #e1e1e1;
      }
      .remove {
        position: absolute;
        width: 100px;
        height: 100px;
        left: 10px;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0;
        transition: opacity 0.3s;
        &:hover {
          opacity: 1;
        }
        .icon {
          color: #fff;
        }
      }
      img {
        max-width: 100px;
        max-height: 100px;
      }
    }
  }
  .upload-button {
    position: relative;
    height: 100px;
    width: 100px;
    padding: 8px;
    border: 1px dashed #e1e1e1;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      border-color: $primary-color;
    }
  }
}
