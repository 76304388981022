#route-order-list {
  .filter-container {
    padding: 16px 32px;
    padding-top: 0;
    background-color: #fff;
    .filter-row {
      margin-top: 16px;
      .label {
        margin: 0 32px;
      }
      html[dir="rtl"] & {
        .label {
          display: inline-block;
          width: 96px;
          white-space: pre;
        }
      }
    }
  }
  .list-container {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    .list-wrap {
      flex: 2;
      margin-right: 8px;
      &:last-child {
        margin-right: 0px;
      }
      &.todays-order {
        flex: 1;
      }
      html[dir="rtl"] & {
        margin-right: 0px;
        margin-left: 8px;
        &:last-child {
          margin-left: 0px;
        }
      }
      .col-box {
        .box-header {
          height: 40px;
          line-height: 40px;
          padding: 0 8px;
          background-color: #f9f9f9;
        }
        .box-container {
          background-color: #fff;
        }
        &.orders {
          margin-bottom: 24px;
          .box-header {
            padding: 0 16px;
            color: #414141;
            display: flex;
            flex-direction: row;
            align-items: center;
            .print {
              color: #fff;
              border-radius: 5px;
              border: 1px solid $color-yellow;
              padding: 0 10px;
              height: 30px;
              line-height: 30px;
              margin: 0 10px;
              background: $color-yellow;
            }
            .num {
              font-size: 22px;
              font-weight: bold;
              span {
                font-size: 14px;
              }
            }
            .info {
              flex: 1;
              margin: 0 16px;
              font-weight: bold;
              .text {
                &.warning {
                  color: $color-yellow;
                }
              }
              .ant-divider {
                background-color: #414141;
              }
            }
            .status {
              font-size: 14px;
              font-weight: bold;
            }
          }
          .box-container {
            .info-row {
              padding: 8px 16px;
              border-bottom: 1px solid #ededed;
              &.receiver {
                .info {
                  .name {
                    color: #414141;
                    font-size: 15px;
                    font-weight: bold;
                  }
                  .label {
                    margin: 0 16px;
                    color: #8e8e8e;
                  }
                }
                .contact {
                  margin: 4px 0;
                  .phone {
                    color: #5b5b6a;
                  }
                }
                .address {
                  color: #5b5b6a;
                  .location {
                    margin: 0 16px;
                    color: $color-green;
                    display: inline-block;
                    direction: ltr;
                  }
                }
              }
              &.deliver {
                & {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                }
                .info {
                  flex: 1;
                  display: flex;
                  flex-direction: column;
                  .inner-row {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    .name {
                      color: #414141;
                      font-size: 15px;
                      font-weight: bold;
                    }
                    .phone {
                      margin: 0 8px;
                      color: #5b5b6a;
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                      align-items: center;
                      i {
                        margin: 0 4px;
                        font-size: 10px;
                      }
                    }
                    .time {
                      margin-top: 4px;
                      color: #8e8e8e;
                    }
                    .status {
                      margin: 0 16px;
                      margin-top: 4px;
                      color: #8e8e8e;
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                      align-items: center;
                      i {
                        margin: 0 4px;
                        font-size: 10px;
                      }
                    }
                  }
                }
                .actions {
                  .ant-btn {
                    margin: 0 4px;
                    padding: 0 8px;
                    border-color: $color-green;
                    font-size: 12px;
                    color: $color-green;
                    &:last-child {
                      margin: 0px;
                    }
                  }
                }
              }
              &.products {
                padding: 0;
                border: unset;
                .header {
                  padding: 8px 16px;
                  padding-bottom: 0;
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                  .title {
                    color: #414141;
                    font-size: 16px;
                    font-weight: bold;
                  }
                  a {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    span {
                      margin: 0 4px;
                    }
                    i {
                      margin-top: 3px;
                      font-size: 8px;
                    }
                  }
                }
                .ant-collapse {
                  padding: 0 0 8px 16px;
                  padding-right: 0;
                  .ant-collapse-item {
                    border: unset;
                    .ant-collapse-header {
                      display: none;
                      border: unset;
                    }
                    .ant-collapse-content {
                      padding: 0;
                      border: unset;
                      .ant-collapse-content-box {
                        padding: 0;
                        .extra {
                          padding: 8px 0;
                          padding-right: 16px;
                          border-bottom: 1px solid #ededed;
                          font-size: 12px;
                          display: flex;
                          flex-direction: row;
                          align-items: flex-start;
                          .title {
                            color: $color-yellow;
                          }
                          .items {
                            display: flex;
                            flex-direction: column;
                            .item {
                              color: #5b5b6a;
                            }
                          }
                        }
                        .details {
                          padding: 8px 0;
                          padding-right: 16px;
                          border-bottom: 1px solid #ededed;
                          .item {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            .name {
                              flex: 3;
                              color: #414141;
                              font-size: 15px;
                              margin: 0 15px;
                            }
                            .price {
                              flex: 1;
                              text-align: end;
                              color: #5b5b5b;
                            }
                            .quantity {
                              flex: 1;
                              text-align: end;
                              color: #5b5b5b;
                            }
                            .item_total {
                              flex: 1.5;
                              text-align: end;
                              color: #414141;
                              font-size: 15px;
                            }
                          }
                          .gift-item {
                            flex-direction: row;
                            align-items: center;
                            margin-bottom: 20px;
                            .name {
                              flex: 3;
                              color: rgba(0, 0, 0, 0.65);
                              font-size: 15px;
                              margin: 0 15px;
                            }
                            .quantity {
                              flex: 1;
                              text-align: end;
                              margin: 0 10px;
                              color: rgba(0, 0, 0, 0.65);
                            }
                          }
                        }
                        .order-gift {
                          padding: 8px 0;
                          padding-right: 16px;
                          border-bottom: 1px solid #ededed;
                          display: flex;
                          flex-direction: column;
                          .quantity {
                            margin: 0 10px;
                          }
                        }
                        .dishware-fee {
                          padding: 8px 0;
                          padding-right: 16px;
                          border-bottom: 1px solid #ededed;
                          display: flex;
                          flex-direction: column;
                          div {
                            color: #5b5b5b;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            .title {
                              font-size: 15px;
                            }
                          }
                        }
                        .subtotal {
                          padding: 8px 0;
                          padding-right: 16px;
                          border-bottom: 1px solid #ededed;
                          div {
                            color: #5b5b5b;
                            font-size: 12px;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            i {
                              margin: 0 4px;
                              font-size: 10px;
                              color: #8e8e8e;
                            }
                            .price {
                              display: inline-block;
                              direction: ltr;
                            }
                            &.subtotal-header {
                              margin-bottom: 8px;
                              color: #414141;
                            }
                          }
                        }
                        .total {
                          padding: 8px 0;
                          padding-bottom: 0;
                          padding-right: 16px;
                          .merchant-total {
                            color: #414141;
                            font-weight: 600;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                          }
                          .user-total {
                            color: #5b5b5b;
                            font-size: 13px;
                            .price {
                              margin: 0 4px;
                              display: inline-block;
                              direction: ltr;
                            }
                            .status {
                              color: #96ce5a;
                            }
                            .paytype {
                              color: #96ce5a;
                              font-size: 24px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .box-footer {
            margin-top: 4px;
            text-align: end;
            color: #5b5b5b;
            font-size: 12px;
            .ant-divider {
              background: #d1cccc;
            }
            .track-number {
              display: inline-block;
              direction: ltr;
              a {
                &:first-child {
                  span {
                    display: inline-block;
                    direction: ltr;
                  }
                }
                &:last-child {
                  margin-left: 4px;
                  i {
                    font-size: 14px;
                  }
                }
              }
            }
            html[dir="rtl"] & {
              .time {
                .time-value {
                  display: inline-block;
                  direction: ltr;
                }
              }
              .track-number {
                direction: rtl;
                a {
                  &:last-child {
                    margin-left: unset;
                    margin-right: 4px;
                  }
                }
              }
            }
          }
          &:first-child {
            margin-top: 8px;
          }
        }
        &.todays-order {
          .box-header {
            .title {
              font-size: 12px;
              color: #262626;
              font-weight: bold;
            }
            .add-btn {
              margin: 0 12px;
            }
          }
          .box-container {
            padding: 8px;
            padding-bottom: 2px;
            h4 {
              margin: 0;
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }
  .list-pagination {
    padding: 16px;
    text-align: center;
  }
}

#route-order-list-item-phone-popover {
  span {
    font-size: 14px;
    color: #5b5b6a;
  }
}

#route-order-list-item-status-popover {
  .ant-steps {
    .ant-steps-item {
      &:last-child {
        .ant-steps-item-content {
          min-height: 24px;
        }
      }
    }
  }
  html[dir="rtl"] & {
    .ant-steps {
      .ant-steps-item {
        .ant-steps-item-tail {
          left: unset;
          right: 12px;
        }
        .ant-steps-item-icon {
          float: right;
          margin-right: unset;
          margin-left: 16px;
        }
        .ant-steps-item-content {
          text-align: start;
          .ant-steps-item-title {
            padding-right: unset;
            padding-left: 12px;
          }
        }
      }
    }
  }
}

#route-order-list-item-subtotal-merchant-popover {
  div {
    font-size: 14px;
    color: #5b5b6a;
  }
  html[dir="rtl"] & {
    div {
      text-align: start;
    }
  }
}

#route-order-list-item-subtotal-platform-popover {
  span {
    font-size: 14px;
    color: #5b5b6a;
  }
}

.route-order-list-send-modal {
  width: 550px !important;
  .ant-modal-body {
    padding: 0;
  }
  .order-send-wrap {
    max-height: 550px;
    padding: 16px;
    background-color: transparent;
    overflow-y: scroll;
    .ant-form-item-children {
      span {
        &.unit {
          margin: 0 4px;
          font-size: 14px;
          color: #333;
        }
      }
    }
  }
  html[dir="rtl"] & {
    .order-send-wrap {
      .ant-form {
        .ant-row {
          .ant-col-4,
          .ant-col-8 {
            float: right;
          }
          .ant-col-4 {
            width: 22.666667%;
            label {
              white-space: pre;
              &::before {
                margin-right: unset;
                margin-left: 4px;
              }
              &::after {
                margin: 0 2px 0 8px;
              }
            }
          }
          .ant-col-8 {
            width: 44.333333%;
          }
        }
      }
    }
  }
}

.route-order-list-100-modal {
  width: 750px !important;
  .ant-modal-body {
    padding: 0;
  }
  .order-100-wrap {
    height: 650px;
    background-color: transparent;
    overflow: hidden;
  }
}

.order-refund-modal-list {
  max-height: 320px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.printView {
  .header {
    text-align: center;
    line-height: 28px;
  }
  .body-top {
    border: 1px solid #f0f0f0;
    line-height: 30px;
  }
  .ant-table-title {
    padding: 0;
  }
  .ant-table-footer {
    padding: 0;
  }
  .footer {
    line-height: 30px;
    border: 1px solid #f0f0f0;
    .section {
      width: 25%;
      display: inline-block;
    }
  }
}
