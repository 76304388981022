.finance-bill-list-header {
  padding: 8px 0px;
  background-color: #f9f9f9;
  font-size: 12px;
  color: #5f5f5f;
  display: flex;
  flex-direction: row;
  &.margin-top {
    margin-top: 16px;
  }
  .date {
    flex: 3.6;
    text-align: center;
  }
  .sum {
    flex: 3.4;
    text-align: center;
  }
  .progress {
    flex: 7.7;
    text-align: center;
  }
  .actions {
    flex: 3.2;
    text-align: center;
  }
  .details {
    flex: 5.5;
    text-align: center;
  }
  html[dir="rtl"] & {
    .progress {
      flex: 6;
    }
    .actions {
      flex: 3.9;
    }
  }
}

.finance-bill-list-group {
  $item-height: 40px;
  justify-content: center;
    align-items: center;
  display: flex;
  flex-direction: row;
  .group-items {
    flex: 18;
    flex-direction: column;
    .group-item {
      min-height: 90px;
      display: flex;
      flex-direction: row;
      .date {
        flex: 3;
        min-height: $item-height;
        line-height: $item-height;
        padding: 0 4px;
        font-size: 12px;
      }
      .sum {
        position: relative;
        flex: 3;
        min-height: $item-height;
        line-height: $item-height;
        box-sizing: border-box;
        padding: 0 8px;
        border-left: 1px solid #e1e1e1;
        text-align: start;
        background-color: #f4fffe;
        &::before {
          content: "";
          position: absolute;
          left: -3px;
          top: 44%;
          width: 6px;
          height: 6px;
          border-radius: 3px;
          background-color: #e1e1e1;
        }
        .unit {
          font-size: 10px;
        }
        .total {
          font-size: 16px;
          color: #414141;
          font-weight: 500;
          .succeeded {
            font-size: 10px;
            font-weight: normal;
            display: block;
            height: 10px;
            line-height: 10px;
          }
        }
      }
      .progress {
        flex: 6;
        min-height: $item-height;
        line-height: $item-height;
        box-sizing: border-box;
        padding: 0 16px;
        background-color: #f4fffe;
        .ant-progress-inner {
          background-color: transparent;
        }
      }
      .actions {
        flex: 3;
        min-height: $item-height;
        line-height: $item-height;
        background-color: #f4fffe;
        text-align: center;
      }
      &:last-child {
        .sum,
        .progress,
        .actions {
          border-bottom: 1px dashed #e1e1e1;
        }
      }
      html[dir="rtl"] & {
        .sum {
          border-left: 0px solid #e1e1e1;
          border-right: 1px solid #e1e1e1;
          &::before {
            left: unset;
            right: -3px;
          }
        }
        .progress {
          flex: 5;
        }
        .actions {
          flex: 4;
        }
      }
    }
  }
  .group-details {
    flex: 5;
    box-sizing: border-box;
    padding: 0 16px;
    border-bottom: 1px dashed #e1e1e1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .time {
      font-size: 12px;
      color: #5f5f5f;
    }
    .sum {
      margin: 4px 0;
      color: #414141;
      font-weight: 500;
      .unit {
        font-size: 10px;
      }
      .total {
        font-size: 20px;
      }
    }
    .info {
      font-size: 12px;
      color: $color-green;
    }
  }
  &:last-child {
    .group-items {
      .group-item {
        .sum,
        .progress,
        .actions {
          border-bottom: unset;
        }
      }
    }
    .group-details {
      border-bottom: unset;
    }
  }
  &.unsettled {
    .group-items {
      .group-item {
        .sum {
          background-color: #fffbf1;
        }
        .progress {
          background-color: #fffbf1;
          .ant-progress-bg {
            background-color: $color-yellow;
          }
        }
        .actions {
          background-color: #fffbf1;
        }
      }
    }
    .group-details {
      .info {
        cursor: default;
        color: $color-yellow;
      }
    }
  }
}

#route-finance-home {
  .bill-brief {
    background-color: #fff;
    .bill-brief-header {
      padding: 8px 16px;
      background-color: #f9f9f9;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .bill-brief-list {
      padding: 16px;
      .list-title {
        position: relative;
        padding: 0 16px;
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 4px;
          height: 100%;
          background-color: $color-green;
          html[dir="rtl"] & {
            left: unset;
            right: 0;
          }
        }
        span {
          color: $color-green;
        }
      }
      .list-items {
        border: 1px solid #e1e1e1;
        border-top: none;
        .list-message {
          margin-top: 16px;
          text-align: center;
        }
      }
      &.unsettled {
        padding-bottom: 0px;
        .list-title {
          &:before {
            background-color: $color-yellow;
          }
          span {
            color: $color-yellow;
          }
        }
      }
    }
  }
}

#route-finance-check {
  .header-tabs {
    background-color: #f9f9f9;
    display: flex;
    flex-direction: row;
    a {
      padding: 8px 16px;
      color: #5f5f5f;
      &.current {
        border-radius: 8px;
        background-color: $color-green;
        color: #fff;
        transform: scale(1.1, 1.1);
        transition: all 0.3s;
      }
    }
  }
  .header-filters {
    margin-top: 16px;
    padding: 16px 16px;
    padding-top: 0;
    background-color: #fff;
    .filter-row {
      margin-top: 16px;
      .label {
        margin: 0 16px;
      }
      .date-all {
        margin: 0 16px;
      }
    }
  }
  .list-items {
    background-color: #fff;
    min-height: 100px;
    .list-message {
      margin-top: 16px;
      text-align: center;
    }
  }
  .list-pagination {
    padding: 16px;
    text-align: center;
  }
}

#route-finance-check-daily {
  .header-wrap {
    .header-title {
      padding: 8px;
      background-color: #f9f9f9;
      display: flex;
      flex-direction: row;
      align-items: center;
      .back {
        font-weight: 600;
        i {
          margin-right: 4px;
          font-weight: 600;
          html[dir="rtl"] & {
            transform: rotate(180deg);
          }
        }
      }
      .ant-divider {
        top: 0.5px;
        width: 2px;
        height: 1em;
        margin: 0 16px 0 8px;
        background: $color-green;
        html[dir="rtl"] & {
          margin: 0 8px 0 16px;
        }
      }
      .title {
        color: #414114;
      }
      .anticon-calendar {
        margin: 0 8px;
        font-size: 16px;
        color: $color-green;
      }
      .status {
        flex: 1;
        text-align: end;
        color: $color-green;
        &.unsettled {
          color: $color-yellow;
        }
        i {
          margin: 0 8px;
        }
      }
    }
    .header-sum {
      padding: 16px;
      background-color: #fff;
      font-size: 20px;
      color: #414141;
    }
  }
  .list-wrap {
    margin-top: 16px;
    background-color: #fff;
    .list-tabs {
      .main-tabs {
        background-color: #f9f9f9;
        display: flex;
        flex-direction: row;
        align-items: center;
        span {
          position: relative;
          padding: 12px 16px;
          color: #414114;
          &.current {
            background-color: $color-green;
            color: #fff;
            &:before {
              content: "";
              position: absolute;
              left: 47%;
              bottom: -4px;
              width: 8px;
              height: 8px;
              background-color: #fff;
              transform: rotate(45deg);
            }
          }
        }
        .unit {
          flex: 1;
          text-align: end;
          padding: 0 8px;
        }
      }
      .sub-tabs {
        background-color: #fff;
        padding: 12px 16px;
        a {
          &.current {
            padding: 4px 0;
            border-bottom: 2px solid $color-green;
          }
        }
      }
    }
    .list-header {
      padding: 8px 0;
      border-top: 1px solid #e1e1e1;
      border-bottom: 1px solid #e1e1e1;
      display: flex;
      flex-direction: row;
      align-items: center;
      span {
        text-align: center;
        font-size: 12px;
        color: #9f9f9f;
      }
      .status {
        flex: 2;
      }
      .date {
        flex: 1.5;
      }
      .type {
        flex: 1;
      }
      .product-fee {
        flex: 1;
      }
      .pack-fee {
        flex: 1;
      }
      .event-fee {
        flex: 1;
      }
      .platform-fee {
        flex: 1;
      }
      .ship-fee {
        flex: 1;
      }
      .donate-fee {
        flex: 1;
      }
      .total-fee {
        flex: 1;
      }
      .actions {
        flex: 1;
      }
      &.other {
        .date {
          flex: 1;
        }
        .type-details {
          flex: 1;
        }
        .total-fee {
          flex: 1;
        }
        .actions {
          flex: 1;
        }
      }
    }
    .list-items {
      min-height: 410px;
      .no-data {
        padding: 8px;
        text-align: center;
        color: #9f9f9f;
      }
      .list-item {
        border-bottom: 1px solid #e1e1e1;
        display: flex;
        flex-direction: row;
        align-items: center;
        span {
          min-height: 40px;
          line-height: 40px;
          text-align: center;
          font-size: 14px;
          color: #414141;
          &.status {
            min-height: 20px;
            line-height: 20px;
            width: 180px;
            span {
              display: inline;
            }
            &.ant-tag-error {
              color: #f5222d;
              background: #fff1f0;
              border-color: #ffa39e;
            }
            &.ant-tag-success {
              color: #52c41a;
              background: #f6ffed;
              border-color: #b7eb8f;
            }
          }
        }
        .date {
          flex: 1.5;
        }
        .type {
          flex: 1;
        }
        .product-fee {
          flex: 1;
          direction: ltr;
          background-color: #f9f9f9;
        }
        .pack-fee {
          flex: 1;
          direction: ltr;
          background-color: #f9f9f9;
        }
        .event-fee {
          flex: 1;
          direction: ltr;
          background-color: #f9f9f9;
        }
        .platform-fee {
          flex: 1;
          direction: ltr;
          background-color: #f9f9f9;
        }
        .ship-fee {
          flex: 1;
          direction: ltr;
          background-color: #f9f9f9;
        }
        .donate-fee {
          flex: 1;
          direction: ltr;
          background-color: #f9f9f9;
        }
        .total-fee {
          flex: 1;
          direction: ltr;
          color: $color-yellow;
        }
        .actions {
          flex: 1;
        }
        &.other {
          .date {
            flex: 1;
          }
          .type-details {
            flex: 1;
          }
          .total-fee {
            flex: 1;
            direction: ltr;
          }
          .actions {
            flex: 1;
          }
        }
      }
    }
    .list-pagination {
      border-top: 1px solid #e1e1e1;
      border-bottom: 1px solid #e1e1e1;
      text-align: center;
      padding: 16px;
    }
    .list-footer {
      padding: 16px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      span {
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: #414141;
      }
      .total-count {
        flex: 2.5;
      }
      .product-fee {
        flex: 1;
        direction: ltr;
      }
      .pack-fee {
        flex: 1;
        direction: ltr;
      }
      .event-fee {
        flex: 1;
        direction: ltr;
      }
      .platform-fee {
        flex: 1;
        direction: ltr;
      }
      .ship-fee {
        flex: 1;
        direction: ltr;
      }
      .donate-fee {
        flex: 1;
        direction: ltr;
      }
      .total-fee {
        flex: 1;
        direction: ltr;
        text-align: center;
        color: $color-yellow;
      }
      .actions {
        flex: 1;
      }
    }
  }
}

#route-finance-order-detail {
  .header-wrap {
    padding: 8px;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: row;
    align-items: center;
    .back {
      font-weight: 600;
      i {
        margin-right: 4px;
        font-weight: 600;
        html[dir="rtl"] & {
          margin-right: 0px;
          margin-left: 4px;
          transform: rotate(180deg);
        }
      }
    }
    .ant-divider {
      top: 0.5px;
      width: 2px;
      height: 1em;
      margin: 0 8px;
      background: $color-green;
    }
    .title {
      color: #414114;
      .no {
        display: inline-block;
        direction: ltr;
        margin: 0 8px;
      }
    }
  }
  .detail-wrapper {
    padding: 8px;
    background-color: #fff;
    .detail-row {
      padding-bottom: 8px;
      color: #6a6a6a;
      display: flex;
      flex-direction: row;
      align-items: center;
      .label {
        width: 200px;
        text-align: end;
        i {
          margin: 0 8px;
        }
        &.black {
          color: #414141;
        }
        &.bold {
          font-weight: bold;
        }
      }
      .amount {
        width: 80px;
        margin: 0 24px;
        &.black {
          color: #414114;
        }
        &.color-green {
          color: $color-green;
        }
        &.bold {
          font-weight: bold;
        }
      }
      .price {
        width: 100px;
        direction: ltr;
        color: $color-red;
        &.black {
          color: #414114;
        }
        .original_price{
          text-decoration: line-through;
          color: #414141;
        }
      }
      html[dir="rtl"] & {
        .label {
          direction: ltr;
          text-align: start;
          i {
            margin: 0 8px;
            margin-left: 0px;
          }
        }
        .amount {
          direction: ltr;
          text-align: end;
        }
      }
    }
    .ant-divider {
      margin: 8px 0;
    }
  }
  .detail-footer {
    padding: 8px 0;
    color: #6a6a6a;
    font-size: 12px;
    .ant-divider {
      background-color: #6a6a6a;
    }
    html[dir="rtl"] & {
      text-align: end;
    }
  }
}

#route-finance-account {
  .account-info {
    .box-header {
      padding: 8px;
      background-color: #f9f9f9;
      color: #414141;
    }
    .box-content {
      min-height: 132px;
      padding: 8px;
      background-color: #fff;
      .box-row {
        margin-bottom: 8px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        .label {
          flex: 1;
          text-align: end;
          color: #999;
        }
        .value {
          flex: 2;
          padding: 0 24px;
          color: #414141;
        }
      }
    }
  }
  .bill-flow-list {
    .flow-list-title {
      margin-top: 32px;
      padding: 8px;
      background-color: #f9f9f9;
      color: #414141;
    }
    .flow-list-filter {
      padding: 16px 0;
      background-color: #fff;
      .filter-row {
        padding: 0 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        .label {
          color: #414141;
        }
        .ant-radio-group {
          margin: 0 24px;
          .ant-radio-button-wrapper {
            padding: 0 12px;
            border-color: $color-green;
            font-size: 12px;
            &:before {
              background-color: $color-green;
            }
          }
          .ant-radio-button-wrapper-checked {
            background-color: $color-green;
            color: #fff;
          }
        }
      }
    }
    .flow-list-header {
      margin-top: 16px;
      padding: 8px 0;
      background-color: #f9f9f9;
      color: #414141;
      display: flex;
      flex-direction: row;
      align-items: center;
      .date {
        flex: 2;
        padding-left: 16px;
        text-align: start;
        html[dir="rtl"] & {
          padding-left: 0px;
          padding-right: 16px;
        }
      }
      .name {
        flex: 3;
        text-align: start;
      }
      .amount {
        flex: 1.6;
        text-align: end;
      }
      .status {
        flex: 2;
        text-align: center;
      }
      .action {
        flex: 1;
        text-align: center;
      }
      &.item {
        margin-top: 0;
        padding: 12px 0;
        background-color: #fff;
        &:nth-child(even) {
          background-color: #f9f9f9;
        }
        .amount {
          color: $color-green;
        }
      }
    }
    .flow-list-items {
      .items-message {
        padding: 16px;
        background-color: #fff;
        text-align: center;
      }
    }
    .flow-list-pagination {
      text-align: center;
      padding: 16px;
    }
  }
}

#route-finance-bill-detail {
  .header-wrap {
    padding: 8px;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: row;
    align-items: center;
    .back {
      font-weight: 600;
      i {
        margin-right: 4px;
        font-weight: 600;
        html[dir="rtl"] & {
          transform: rotate(180deg);
        }
      }
    }
    .ant-divider {
      top: 0.5px;
      width: 2px;
      height: 1em;
      margin: 0 8px;
      background: $color-green;
    }
    .title {
      color: #414114;
    }
  }
  .info-wrap {
    padding: 16px 0;
    padding-bottom: 0px;
    background-color: #fff;
    .info-row {
      margin-bottom: 16px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      .label {
        width: 130px;
        text-align: end;
        color: #999;
      }
      .value {
        flex: 1;
        margin: 0 16px;
        color: #414114;
      }
    }
    .info-message {
      padding-bottom: 16px;
      text-align: center;
    }
  }
  .progress-wrap {
    margin-top: 16px;
    .title {
      padding: 8px;
      background-color: #f9f9f9;
      color: #414114;
    }
    .content {
      padding: 16px;
      background-color: #fff;
      .ant-steps {
        width: 510px;
        .ant-steps-item-content {
          .ant-steps-item-title {
            font-size: 12px;
          }
          .ant-steps-item-description {
            text-align: center;
            font-size: 12px;
          }
        }
      }
    }
  }
}

#route-finance-download {
  .header-wrap {
    padding: 8px 16px;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: row;
    align-items: center;
    a {
      padding: 4px 12px;
      border: 1px solid $color-green;
      background-color: #fff;
      color: $color-green;
      font-size: 12px;
      &.current {
        background-color: $color-green;
        color: #fff;
      }
    }
    i {
      margin: 0 16px;
      html[dir="rtl"] & {
        transform: rotate(180deg);
      }
    }
  }
  .generate-wrap {
    .generate-filter {
      padding: 16px 0;
      background-color: #fff;
      .filter-row {
        padding: 0 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        .label {
          color: #414141;
        }
        .margin {
          html[dir="rtl"] & {
            margin-left: 16px;
          }
          html[dir="ltr"] & {
            margin-right: 16px;
          }
        }
        .ant-radio-group {
          margin: 0 24px;
          .ant-radio-button-wrapper {
            padding: 0 12px;
            border-color: $color-green;
            font-size: 12px;
            &:before {
              background-color: $color-green;
            }
          }
          .ant-radio-button-wrapper-checked {
            background-color: $color-green;
            color: #fff;
          }
        }
      }
    }
    .generate-button {
      padding: 16px;
      text-align: center;
    }
  }
  .history-wrap {
    padding: 8px;
    background-color: #fff;
    .history-header {
      padding: 8px 0;
      background-color: #f9f9f9;
      display: flex;
      flex-direction: row;
      align-items: center;
      .no {
        flex: 1;
        padding-left: 8px;
      }
      .name {
        flex: 3;
        html[dir="rtl"] & {
          direction: ltr;
          text-align: right;
        }
      }
      .date {
        flex: 2;
      }
      .count {
        flex: 1;
      }
      .status {
        flex: 1;
      }
      .actions {
        flex: 1;
        text-align: center;
      }
      &.item {
        padding: 12px 0;
        background-color: unset;
        border-bottom: 1px solid #e1e1e1;
        .status {
          color: $color-green;
          i {
            margin: 0 4px;
          }
          &.progress {
            color: $color-yellow;
          }
          &.error {
            color: $color-red;
          }
        }
        .actions {
          a {
            margin: 0 4px;
          }
        }
      }
      html[dir="rtl"] & {
        .no {
          padding-left: 0px;
          padding-right: 8px;
        }
      }
    }
    .history-list {
      .list-message {
        padding-top: 16px;
        padding-bottom: 8px;
        text-align: center;
      }
    }
    .history-pagination {
      padding: 16px;
      padding-bottom: 8px;
      text-align: center;
    }
  }
}
