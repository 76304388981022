#route-404 {
	width: 100%;
	height: 100%;
	background-color: #f5f5f5;
	display: flex;
	flex-direction: column;
	align-items: center;
	.not-fount-img {
		width: 424px;
		height: auto;
	}
	.not-fount-actions {
		margin-top: 32px;
		width: 424px;
		direction: ltr;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		.ant-btn {
			border-color: $color-green;
			color: $color-green;
			&.ant-btn-primary {
				color: #ffffff;
			}
		}
	}
}