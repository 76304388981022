#route-promotion-shop {
	.header-slider {
		.slick-slide {
			text-align: center;
			height: 160px;
			line-height: 160px;
			background: #364d79;
			overflow: hidden;
		}
		.slick-slide h3 {
			color: #fff;
		}
	}
	.grid-wrap {
		margin-top: 16px;
		.grid-title {
			padding: 8px;
			background-color: #f9f9f9;
		}
		.grid-content {
			padding: 8px;
			background-color: #fff;
			display: flex;
			flex-wrap: wrap;
			.grid-item-wrap {
				width: 25%;
				box-sizing: border-box;
				padding: 4px;
				.grid-item {
					position: relative;
					padding: 8px;
					border: 1px solid #e1e1e1;
					background-color: #f9f9f9;
					display: flex;
					flex-direction: row;
					.item-icon {
						padding: 8px 12px;
						border-radius: 8px;
						margin-right: 8px;
						color: #fff;
						font-size: 18px;
					}
					.item-info {
						flex: 1;
						display: flex;
						flex-direction: column;
						justify-content: space-around;
						.item-name {
							color: #414141;
							font-size: 12px;
						}
						.item-description {
							color: #999;
							font-size: 10px;
						}
					}
					.ant-btn {
						position: absolute;
						right: 8px;
						top: 8px;
						font-size: 10px;
					}
				}
			}
		}
	}
	.list-wrap {
		margin-top: 16px;
		.list-title {
			padding: 8px;
			background-color: #f9f9f9;
		}
		.list-content {
			padding: 8px;
			background-color: #fff;
		}
		.list-tabs {
			display: flex;
			flex-direction: row;
			border-bottom: 1px solid $color-green;
			a {
				padding: 4px 16px;
				color: #414141;
				font-size: 12px;
				&.current {
					color: $color-green;
					border-bottom: 3px solid $color-green;
				}
			}
		}
		.list-header {
			margin-top: 8px;
			border-top: 1px solid #e1e1e1;
			border-bottom: 1px solid #e1e1e1;
			background-color: #f9f9f9;
			display: flex;
			flex-direction: row;
			.name {
				flex: 2;
				padding: 8px;
				text-align: center;
				border-left: 1px solid #e1e1e1;
			}
			.status {
				flex: 1;
				padding: 8px;
				text-align: center;
				border-left: 1px solid #e1e1e1;
				border-right: 1px solid #e1e1e1;
			}
			.time {
				flex: 1;
				padding: 8px;
				text-align: center;
			}
			.content {
				flex: 2;
				padding: 8px;
				text-align: center;
				border-left: 1px solid #e1e1e1;
				border-right: 1px solid #e1e1e1;
			}
			.actions {
				flex: 1;
				padding: 8px;
				text-align: center;
				border-right: 1px solid #e1e1e1;
			}
			&.item {
				margin-top: unset;
				border-top: unset;
				background-color: unset;
				.name {
					text-align: start;
					.icon {
						margin-right: 4px;
						padding: 4px;
						border-radius: 4px;
						color: #fff;
					}
				}
				.status {
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					.text {
						margin-bottom: 8px;
						color: $color-green;
						&.finish {
							color: $color-yellow;
						}
					}
				}
				.content {
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					span {
						margin-bottom: 8px;
					}
				}
				.actions {
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					a {
						margin-bottom: 8px;
						&:last-child {
							margin-bottom: unset;
						}
					}
				}
			}
		}
		.list-pagination {
			padding: 16px;
			text-align: center;
		}
	}
}


#route-promotion-shop-ticket {
	.header-wrap {
		padding: 8px;
		background-color: #f9f9f9;
		display: flex;
		flex-direction: row;
		align-items: center;
		.back {
			font-weight: 600;
			i {
				margin-right: 4px;
				font-weight: 600;
			}
		}
		.ant-divider {
			top: 0.5px;
			width: 2px;
			height: 1em;
			margin: 0 8px;
			background: $color-green;
		}
		.title {
			color: #414114;
		}
	}
	.content-wrap {
		background-color: #fff;
		display: flex;
		flex-direction: row;
	}
	.content-main {
		flex: 1;
		padding: 16px;
		.content-main-advise {
			padding: 16px;
			border: 1px dashed #fe8226;
			background-color: #fefbe9;
			.title {
				display: block;
				color: #414141;
				font-weight: bold;
			}
			.text {
				margin-top: 8px;
				color: #414141;
				font-size: 12px;
				text-align: justify;
			}
		}
		.content-main-tickets {
			.ticket-item {
				width: 287.2px;
				margin-top: 24px;
				.ticket-header {
					font-size: 12px;
					display: flex;
					flex-direction: row;
					align-items: center;
					.title {
						position: relative;
						padding: 0 8px;
						color: #414141;
						font-weight: bold;
						&::before {
							content: '';
							position: absolute;
							left: 0;
							top: 25%;
							width: 4px;
							height: 60%;
							background-color: $color-green;
						}
					}
					.actions {
						flex: 1;
						text-align: end;
						a {
							margin-left: 8px;
						}
					}
				}
				.ticket-content {
					position: relative;
					height: 120px;
					margin-top: 8px;
					box-sizing: border-box;
					padding: 16px;
					border: 1px solid #e1e1e1;
					border-radius: 8px;
					display: flex;
					flex-direction: row;
					&::before {
						content: '';
						position: absolute;
						left: -8px;
						top: 52px;
						width: 16px;
						height: 16px;
						border-right: 1px solid #e1e1e1;
						border-radius: 16px;
						background-color: #fff;
					}
					&::after {
						content: '';
						position: absolute;
						right: -8px;
						top: 52px;
						width: 16px;
						height: 16px;
						border-left: 1px solid #e1e1e1;
						border-radius: 16px;
						background-color: #fff;
					}
					.main {
						flex: 1;
						.price {
							border: 1px dashed #e1e1e1;
							color: $color-red;
							text-align: center;
							.unit {
								font-size: 18px;
							}
							.amount {
								font-size: 36px;
							}
						}
						.condition {
							margin-top: 8px;
							border: 1px dashed #e1e1e1;
							color: #414141;
							text-align: center;
							font-size: 14px;
						}
					}
					.ant-divider {
						height: 88px;
					}
					.extra {
						flex: 1;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						.prop {
							margin-top: 8px;
							padding: 1px 4px;
							border: 1px dashed #e1e1e1;
							color: #414141;
							font-size: 12px;
							&:first-child {
								margin-top: unset;
							}
						}
					}
				}
			}
		}
		.content-main-action {
			margin-top: 16px;
			text-align: center;
		}
	}
	.content-divider {
		width: 1px;
		border-right: 1px dashed #e1e1e1;
	}
	.content-extra {
		flex: 1;
		padding: 16px;
		.title {
			color: #414141;
			font-weight: bold;
		}
		.question {
			margin: 0;
			color: #414141;
			font-size: 12px;
			font-weight: bold;
		}
		.answer {
			color: #999;
			font-size: 12px;
			text-align: justify;
		}
		.error {
			color: $color-red;
			font-size: 12px;
		}
	}
	.content-blank {
		flex: 1;
	}
}

.route-promotion-shop-ticket-edit-modal {
	width: 780px !important;
	.ant-modal-body {
		padding: 0;
	}
	.ticket-edit-wrap {
		max-height: 550px;
		padding: 16px 48px 16px 8px;
		background-color: transparent;
		overflow-y: scroll;
		.money-unit {
			margin: 0 4px;
		}
		.condition {
			.ant-input {
				width: 80px;
			}
		}
		.discount {
			.ant-input {
				width: 80px;
			}
		}
		.amount {
			.ant-input {
				width: 80px;
			}
		}
		textarea {
			resize: none;
		}
	}
}


#route-promotion-new-user {
	.header-wrap {
		padding: 8px;
		background-color: #f9f9f9;
		display: flex;
		flex-direction: row;
		align-items: center;
		.back {
			font-weight: 600;
			i {
				margin-right: 4px;
				font-weight: 600;
			}
		}
		.ant-divider {
			top: 0.5px;
			width: 2px;
			height: 1em;
			margin: 0 8px;
			background: $color-green;
		}
		.title {
			color: #414114;
		}
	}
	.content-wrap {
		min-height: 220px;
		padding-bottom: 32px;
		background-color: #fff;
		.ant-row {
			margin-top: 32px;
			.ant-col-6 {
				text-align: end;
				line-height: 32px;
				color: #414114;
			}
			.money-unit {
				margin: 0 8px;
			}
		}
		.discount {
			.ant-input {
				width: 80px;
			}
		}
	}
	.footer-wrap {
		padding: 8px;
		background-color: #f9f9f9;
		text-align: center;
		.ant-btn {
			margin: 0 12px;
			border-color: $color-green;
			color: $color-green;
			&.ant-btn-primary {
				color: #fff;
			}
		}
	}
}


#route-promotion-demand-reduce {
	.header-wrap {
		padding: 8px;
		background-color: #f9f9f9;
		display: flex;
		flex-direction: row;
		align-items: center;
		.back {
			font-weight: 600;
			i {
				margin-right: 4px;
				font-weight: 600;
			}
		}
		.ant-divider {
			top: 0.5px;
			width: 2px;
			height: 1em;
			margin: 0 8px;
			background: $color-green;
		}
		.title {
			color: #414114;
		}
	}
	.content-wrap {
		min-height: 220px;
		padding-bottom: 32px;
		background-color: #fff;
		.ant-row {
			margin-top: 32px;
			.ant-col-6 {
				text-align: end;
				line-height: 32px;
				color: #414114;
			}
			.ant-checkbox-group {
				margin-top: 4px;
			}
			.day-times-item {
				margin-bottom: 16px;
				.ant-time-picker {
					width: 102px;
					input {
						text-align: center;
					}
					.ant-time-picker-icon {
						display: none;
					}
				}
				.text-divider {
					margin: 0 26px;
				}
				i {
					margin: 0 3px;
					font-size: 18px;
					color: $color-green;
					cursor: pointer;
					&.anticon-plus-circle {
						margin-left: 28px;
					}
					&.disabled {
						color: #cccccc;
						cursor: not-allowed;
					}
				}
			}
			.discount-item {
				margin-bottom: 16px;
				.input-label {
					margin-right: 8px;
				}
				.ant-input {
					width: 80px;
				}
				.money-unit {
					margin-left: 8px;
					margin-right: 24px;
				}
				i {
					margin: 0 3px;
					font-size: 18px;
					color: $color-green;
					cursor: pointer;
					&.disabled {
						color: #cccccc;
						cursor: not-allowed;
					}
				}
			}
		}
	}
	.footer-wrap {
		padding: 8px;
		background-color: #f9f9f9;
		text-align: center;
		.ant-btn {
			margin: 0 12px;
			border-color: $color-green;
			color: $color-green;
			&.ant-btn-primary {
				color: #fff;
			}
		}
	}
}


#route-promotion-ship-price {
	.header-wrap {
		padding: 8px;
		background-color: #f9f9f9;
		display: flex;
		flex-direction: row;
		align-items: center;
		.back {
			font-weight: 600;
			i {
				margin-right: 4px;
				font-weight: 600;
			}
		}
		.ant-divider {
			top: 0.5px;
			width: 2px;
			height: 1em;
			margin: 0 8px;
			background: $color-green;
		}
		.title {
			color: #414114;
		}
	}
	.content-wrap {
		min-height: 220px;
		padding-bottom: 32px;
		background-color: #fff;
		.ant-row {
			margin-top: 32px;
			.ant-col-6 {
				text-align: end;
				line-height: 32px;
				color: #414114;
			}
			.money-unit {
				margin: 0 8px;
			}
		}
		.discount {
			.ant-input {
				width: 80px;
			}
		}
	}
	.footer-wrap {
		padding: 8px;
		background-color: #f9f9f9;
		text-align: center;
		.ant-btn {
			margin: 0 12px;
			border-color: $color-green;
			color: $color-green;
			&.ant-btn-primary {
				color: #fff;
			}
		}
	}
}