#route-main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  .main-sider {
    background-color: #1e2e3c;
    .ant-layout-sider-children {
      display: flex;
      display: -webkit-flex;
      flex-direction: column;
      .logo {
        height: 48px;
        text-align: center;
        border-bottom: 1px solid #243b4d;
        span {
          font-size: 18px;
          color: #fff;
        }
      }
      .ant-menu {
        background-color: transparent;
        overflow-y: auto;
        box-shadow: unset;
        &::-webkit-scrollbar {
          width: 0;
          background-color: transparent;
        }
        .ant-menu-item {
          color: #767c84;
          background-color: transparent;
          &.show-divider {
            margin: 0;
            border-bottom: 1px solid #243b4d;
            color: #fff;
          }
          &.ant-menu-item-selected {
            color: $color-green;
          }
          html[dir="rtl"] & {
            padding-left: 16px !important;
            padding-right: 24px !important;
            text-align: right;
            span {
              margin-left: 10px;
              font-size: 15px;
              i {
                margin-right: 0px;
                margin-left: 14px;
              }
            }
          }
        }
        .ant-menu-submenu {
          color: #fff;
          font-weight: 500;
          border-bottom: 1px solid #243b4d;
          .ant-menu-submenu-title {
            margin: 0;
            .ant-menu-submenu-arrow {
              display: none;
            }
          }
          &.ant-menu-submenu-open {
            background-color: #15212a;
          }
          &.ant-menu-submenu-selected {
            .ant-menu-submenu-title {
              color: $color-green;
            }
          }
          html[dir="rtl"] & {
            .ant-menu-submenu-title {
              padding-left: 16px !important;
              padding-right: 24px !important;
              text-align: right;
              span {
                margin-left: 10px;
                font-size: 15px;
                i {
                  margin-right: 0px;
                  margin-left: 14px;
                }
              }
            }
            .ant-menu-item {
              padding-right: 53px !important;
            }
          }
        }
      }
    }
  }
  .main-layout {
    width: 100%;
    height: 100%;
    min-width: 880px;
    overflow: hidden;
  }
  .main-header {
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      height: auto;
      align-items: flex-start;
    }
    position: relative;
    height: 48px;
    box-sizing: border-box;
    padding: 0;
    background-color: $color-green;
    direction: rtl;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    html[dir="rtl"] & {
      direction: ltr;
    }
    .action-icon {
      width: 48px;
      height: 48px;
      text-align: center;
      line-height: 48px;
      background-color: #2aadd1;
      i {
        color: #fff;
        font-size: 24px;
      }
      .ant-badge-count {
        right: -12px;
        top: -8px;
        height: 16px;
        min-width: 16px;
        line-height: 16px;
        border-radius: 16px;
        font-size: 10px;
        padding: 0 4px;
        &.ant-badge-multiple-words {
          right: -18px;
        }
      }
    }
    .action-dropdown {
      min-width: 96px;
      height: 48px;
      padding: 0 4px;
      direction: ltr;
      text-align: center;
      line-height: 48px;
      i {
        margin: 0px 4px;
        color: #fff;
        font-size: 15px;
      }
      span {
        margin: 0;
        color: #fff;
        display: inline-block;
        direction: ltr;
        html[dir="rtl"] & {
          direction: rtl;
        }
      }
    }
    .action-search {
      position: absolute;
      left: 48px;
      width: 300px;
      input {
        font-size: 14px;
        color: #333;
        direction: ltr;
        &::-webkit-input-placeholder {
          direction: ltr;
          color: #666;
        }
      }
      .ant-input-suffix {
        cursor: pointer;
        i {
          font-size: 16px;
        }
      }
      html[dir="rtl"] & {
        left: unset;
        right: 48px;
        input {
          &::-webkit-input-placeholder {
            font-size: 12px;
            direction: rtl;
          }
        }
      }
    }
  }
  .main-content {
    flex: 1;
    padding: 16px 32px 16px 48px;
    overflow: scroll;
    .no-permission {
      height: 450px;
      background-color: #fff;
      text-align: center;
      i {
        margin-top: 100px;
        font-size: 150px;
        color: #666;
        border: 4px solid #666;
        border-radius: 150px;
      }
      h3 {
        margin-top: 20px;
        font-size: 22px;
        color: #666;
      }
    }
    html[dir="rtl"] & {
      padding: 16px 48px 16px 32px;
    }
  }
}

.route-main-header-menus {
  .ant-dropdown-menu-item {
    min-width: 121px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &.status {
    .ant-dropdown-menu-item {
      min-width: 144px;
      &.current {
        color: $color-green;
      }
    }
  }
}
.ant-modal {
  .ant-modal-body {
    .ant-modal-confirm-body {
      .reduce_balance_form {
        .form {
          margin-top: 20px;
          padding: 0 20px;
        }
      }
    }
  }
}
.bread-crumb {
  background: #fff;
  border-radius: 5px;
  padding: 10px 10px;
  margin: 10px 0;
  border: 1px solid #e1e1e1;
}
.filter-area {
  background: #fff;
  border-radius: 5px;
  padding: 10px;
  margin: 0px 0;
  border: 1px solid #e1e1e1;
  .filter-row {
    margin: 20px;
    .label {
      width: 120px;
      display: inline-block;
    }
  }
}
