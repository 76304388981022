@import "../../../styles/variables.scss";
.edit-course-page {
  min-width: 1400px;
}
.course-form {
  background-color: #fff;
  border-radius: 5px;
  padding: 30px;
  margin: 10px 0;
  border: 1px solid #e1e1e1;

  .ant-form-item {
    max-width: 800px;
    min-width: 700px;
  }
  .ant-form-item-label {
    label {
      height: auto;
    }
  }
  .submit {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    background: #fff;
    width: 100%;
  }
  .basic-info {
    .two-editors {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .ant-form-item-row {
        display: flex;
        flex-direction: column;
      }
    }
  }
  .info {
    flex-direction: column;
    .ant-card-head-title {
      border-left: 4px solid $color-green;
      padding: 0px 10px;
      margin: 10px 0;
    }
  }
}
