@import "../../../styles/variables.scss";

.course-order-detail {
  background-color: #fff;
  border-radius: 5px;
  padding: 30px;
  margin: 10px 0;
  border: 1px solid #e1e1e1;
  .info {
    flex-direction: column;
    .ant-card-head-title {
      border-left: 4px solid $color-green;
      padding: 0px 10px;
      margin: 10px 0;
    }
    .ant-row {
      .ant-col {
        span {
          line-height: 40px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
        }
      }
    }
  }
}
